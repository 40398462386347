import { Formik } from "formik";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PrimaryFormButton } from "../../components/Buttons";
import { CenteredForm } from "../../components/CenteredForm";
import { Email, Phone, TextOrCall, useSenderValues } from "../../hooks/senders";
import { useMount } from "../../hooks/useMount";
import { useReactRouterRedirect } from "../../hooks/useReactRouterRedirect";
import { useUsername } from "../../hooks/username";
import { usePincodeRequestTracking } from "../../hooks/pinCodeRequestTracking";
import { Layout } from "../../Layout";
import { getSenders, sendLoginPinCode } from "../../requests";
import { Headline } from "./Headline";
import { SelectEmail } from "./SelectEmail";
import { SelectPhone } from "./SelectPhone";
import { SelectTextOrCall } from "./SelectTextOrCall";
import { redirectTo } from "../../utils/redirectHelper";
import { useAppConfig } from "../../hooks/appConfig";
import styled from "@emotion/styled";

export function DeliveryPicker(props: { associateExistingAccount?: boolean }) {
  const reactRouterRedirect = useReactRouterRedirect();
  const { t } = useTranslation();
  const [usernameOrCorpEmail] = useUsername();
  const [, setSenderValues] = useSenderValues();
  const [senders, setSenders] = useState(getInitialState());
  const { myPwCEntryPoint, pinCodeRequestQuotaIntervalInSec } = useAppConfig();
  const [lastNonThrottledResponseTime, setLastNonThrottledResponseTime] = usePincodeRequestTracking();
  const [sendCountdownInSec, setSendCountdownInSec] = useState(0);

  useMount(async () => {
    try {
      const response = await getSenders(usernameOrCorpEmail);
      setSenders(response.data);
    } catch (error) {
      if (error.response.status === 404) {
        redirectTo(myPwCEntryPoint);
      }
    }
  });

  function updateSendCountdownInSec() {
    const elapsedSecondsSinceLastNonThrottledResponse =
      Math.floor((new Date().getTime() - lastNonThrottledResponseTime.getTime()) / 1000);

    let countdownValue = 0;
    if (elapsedSecondsSinceLastNonThrottledResponse < pinCodeRequestQuotaIntervalInSec) {
      countdownValue = pinCodeRequestQuotaIntervalInSec - elapsedSecondsSinceLastNonThrottledResponse;
    }

    setSendCountdownInSec(countdownValue);
    return countdownValue;
  }

  useEffect(() => {
    updateSendCountdownInSec();
    const intervalId = setInterval(() => {
      const countdownValue = updateSendCountdownInSec();
      if (countdownValue <= 0) {
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const isUSNumber = senders.primaryPhoneNumber.startsWith('+1') || (senders.secondaryPhoneNumber && senders.secondaryPhoneNumber.startsWith('+1'));

  return (
    <Layout>
      <Formik
        enableReinitialize={true}
        initialValues={{
          phone: !!senders.primaryPhoneNumber
            ? Phone.primaryPhoneNumber
            : Phone.email,
          textOrCall: isUSNumber ? TextOrCall.voiceCall : TextOrCall.message,
          email: Email.primaryEmail,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setSenderValues(values);
          const isSendingByEmail = values.phone === Phone.email;
          const sendThrough = isSendingByEmail
            ? Phone.email
            : values.textOrCall;
          const sender = isSendingByEmail ? values.email : values.phone;
          try {
            await sendLoginPinCode(usernameOrCorpEmail, sender, sendThrough);
            setLastNonThrottledResponseTime(new Date());
            setSubmitting(false);
            reactRouterRedirect(
              props.associateExistingAccount ? "/verify-and-associate" : "/verify"
            );
          }
          catch (error) {
            const status = error.response?.status;
            if (status !== 429) {
              setLastNonThrottledResponseTime(new Date());
            }
            throw error;
          }
        }}
      >
        {({ isSubmitting, values }) => {
          return (
            <CenteredForm>
              <Headline />
              {!!senders.primaryPhoneNumber && (
                <SelectPhone senders={senders} />
              )}
              {values.phone === Phone.email ? (
                <SelectEmail {...senders} />
              ) : (
                <SelectTextOrCall senders={senders} />
              )}

              {
                sendCountdownInSec > 0 &&
                <PrimaryFormButton
                  className="submit-button"
                  disabled={true}
                  data-testid="button-throttled-send-verify-code"
                >
                  {t(`Send Code in {sendCountdownInSec} seconds.`, { sendCountdownInSec })}
                </PrimaryFormButton>
              }
              {
                sendCountdownInSec <= 0 &&
                <PrimaryFormButton
                  className="submit-button"
                  type="submit"
                  disabled={isSubmitting}
                  data-testid="button-send-verify-code"
                >
                  {t("Send")}
                </PrimaryFormButton>
              }

            </CenteredForm>
          );
        }}
      </Formik>
    </Layout>
  );
}

const ErrorText = styled.div`
  color: red;
  margin-bottom: 16px;
  text-align: center;
  font-size: 14px;
`;

function getInitialState() {
  return {
    primaryEmail: "",
    secondaryEmail: "",
    primaryPhoneNumber: "",
    secondaryPhoneNumber: "",
  };
}
