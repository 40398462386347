import i18n from "i18next";
import React, { createContext, ReactNode, useContext, useState } from "react";
import { I18nextProvider } from "react-i18next";
import { AppConfig, CopyrightLinks, getAppConfig } from "../requests";
import { useMount } from "./useMount";

export const AppConfigContext = createContext<AppConfig>({
  cultures: [],
  myPwCEntryPoint: "",
  webSupportContactEmail: "",
  myMobilityHQIntroduction: "",
  myMobilityHQWelcomePageUrl: "",
  resetPasswordInterval: 0,
  pinCodeRequestQuotaIntervalInSec: 0,
  posthogApiKey: "",
  posthogApiHostUrl: "",
  applicationInsightsConnectionString: "",
  copyrightLink: "",
  securityLink: "",
  privacyLink: "",
  cookieInfoLink: "",
  redirectWhiteList: "",
  redirectErrorUrl: "",
});

export function useAppConfig() {
  return useContext(AppConfigContext);
}

export const useCopyrightLinks = (): CopyrightLinks => {
  const appConfig = useAppConfig();

  return {
    webSupportContactEmail: appConfig.webSupportContactEmail,
    copyrightLink: appConfig.copyrightLink,
    securityLink: appConfig.securityLink,
    privacyLink: appConfig.privacyLink,
    cookieInfoLink: appConfig.cookieInfoLink,
  };
};

export function AppConfigProvider(props: { children: ReactNode }) {
  const [appConfig, setAppConfig] = useState<AppConfig>();

  useMount(async () => {
    const response = await getAppConfig();
    setAppConfig(response.data);
  });

  if (appConfig)
    return (
      <I18nextProvider i18n={i18n}>
        <AppConfigContext.Provider value={appConfig}>
          {props.children}
        </AppConfigContext.Provider>
      </I18nextProvider>
    );
  return <></>;
}
