import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Phone, useSenderValues } from "../../hooks/senders";
import { useUsername } from "../../hooks/username";
import { useAppConfig } from "../../hooks/appConfig";
import { usePincodeRequestTracking } from "../../hooks/pinCodeRequestTracking";
import { sendLoginPinCode } from "../../requests";
import { primaryTeal, secondaryTealLight } from "../../styles/variables";
import { useFormikContext } from "formik";
import { AccessCodeField } from "../../components/AccessCodeField";

export function AccessCodeFieldAndResend(props: {
  secondsLeft: number;
  hasExceeded: boolean;
}) {
  const { t } = useTranslation();
  const { setFieldError } = useFormikContext();

  useEffect(() => {
    if (props.hasExceeded) {
      setFieldError(
        "accessCode",
        t(
          "You have exceeded the maximum number of verification attempts. You will be redirected back to Login page in {countDownSeconds} seconds.",
          { countDownSeconds: props.secondsLeft }
        )
      );
    }
  }, [props.secondsLeft, props.hasExceeded, setFieldError, t]);

  return (
    <AccessCodeContainer>
      <AccessCodeField name="accessCode" />
      <ResendButton disabled={props.hasExceeded} />
    </AccessCodeContainer>
  );
}

function ResendButton(props: { disabled: boolean }) {
  const { t } = useTranslation();
  const [sent, setSent] = useState(false);
  const [username] = useUsername();
  const [senderValues] = useSenderValues();
  const { pinCodeRequestQuotaIntervalInSec } = useAppConfig();
  const [lastNonThrottledResponseTime, setLastNonThrottledResponseTime] = usePincodeRequestTracking();
  const [sendCountdownInSec, setSendCountdownInSec] = useState(0);

  function updateSendCountdownInSec() {
    const elapsedSecondsSinceLastNonThrottledResponse =
      Math.floor((new Date().getTime() - lastNonThrottledResponseTime.getTime()) / 1000);

    let countdownValue = 0;
    if (elapsedSecondsSinceLastNonThrottledResponse < pinCodeRequestQuotaIntervalInSec) {
      countdownValue = pinCodeRequestQuotaIntervalInSec - elapsedSecondsSinceLastNonThrottledResponse;
    }

    setSendCountdownInSec(countdownValue);
    return countdownValue;
  }

  useEffect(() => {
    updateSendCountdownInSec();
    const intervalId = setInterval(() => {
      const countdownValue = updateSendCountdownInSec();
      if (countdownValue <= 0) {
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  async function handleResend() {
    const isSendingByEmail = senderValues.phone === Phone.email;
    const sendThrough = isSendingByEmail
      ? Phone.email
      : senderValues.textOrCall;
    const sender = isSendingByEmail ? senderValues.email : senderValues.phone;
    try {
      await sendLoginPinCode(username, sender, sendThrough);
      setSent(true);
      setLastNonThrottledResponseTime(new Date());
    }
    catch (error) {
      const status = error.response?.status;
      if (status !== 429) {
        setLastNonThrottledResponseTime(new Date());
      }
      throw error;
    }
  }

  if (sent) return <DisabledLink>{t("Sent")}</DisabledLink>;
  if (props.disabled) return <DisabledLink>{t("Resend Code")}</DisabledLink>;
  if (sendCountdownInSec > 0) {
    return (
      <DisabledLink>
        {t(
          `Resend Code in {sendCountdownInSec} seconds.`,
          { sendCountdownInSec }
        )}
      </DisabledLink>
    );
  }
  return (
    <ActiveLink onClick={handleResend} data-testid="resend-verify-code-link">
      {t("Resend Code")}
    </ActiveLink>
  );
}

const AccessCodeContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  align-self: center;
  width: 100%;

  .text-field-container {
    padding-bottom: 10px;
  }
`;

const ActiveLink = styled.a`
  margin-top: 16px;
  display: flex;
  align-self: center;
  font-size: 16px;
  color: ${primaryTeal};

  &:hover {
    color: ${secondaryTealLight};
  }
`;

const DisabledLink = styled.span`
  margin-top: 16px;
  display: flex;
  align-self: center;
  font-size: 16px;
  color: ${primaryTeal};
`;
