import React, {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useContext,
    useState,
} from "react";

type DS<T> = Dispatch<SetStateAction<T>>;
type Ctx<T> = [T, DS<T>];

export const PinCodeRequestTrackingContext = createContext<Ctx<Date>>([new Date(), () => { }]);

export function usePincodeRequestTracking(): Ctx<Date> {
    const [lastNonThrottledResponseTime, setLastNonThrottledResponseTime] = useContext(PinCodeRequestTrackingContext);
    return [lastNonThrottledResponseTime, setLastNonThrottledResponseTime];
}

export function PinCodeRequestTrackingProvider(props: { children: ReactNode }) {
    const [lastNonThrottledResponseTime, setLastNonThrottledResponseTime] = useState(new Date("1900-01-01"));

    return (
        <PinCodeRequestTrackingContext.Provider value={[lastNonThrottledResponseTime, setLastNonThrottledResponseTime]}>
            {props.children}
        </PinCodeRequestTrackingContext.Provider>
    );
}
